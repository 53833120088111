












































import '@syncfusion/ej2-vue-grids/styles/fabric.css'
import { Component, Vue } from 'vue-property-decorator'
import { GridPlugin } from '@syncfusion/ej2-vue-grids';
import store from '@/store';

Vue.use(GridPlugin);
@Component
export default class Dashboard extends Vue {
}
